<template>
  <div class="auth" id="reset-pwd">
    <section class="hero is-fullheight">
      <div class="header">
        Nouveau mot de passe
      </div>
      <div class="hero-body" v-if="!success">
        <div class="auth-block">
          <div class="auth-form">
            <form @submit.prevent="create">
              <b-field>
                <b-input type="password" v-model="password" required size="large"
                  password-reveal icon="fas fa-key"
                  placeholder="Mot de passe"></b-input>
              </b-field>
              <b-field>
                <b-input type="password" v-model="passwordConfirmation" required size="large"
                  password-reveal icon="fas fa-key"
                  placeholder="Confirmation du mot de passe"></b-input>
              </b-field>
              <b-button type="is-primary" v-bind:disabled="!password || !passwordConfirmation" native-type="submit" expanded>
                Enregister le nouveau mot de passe
              </b-button>
            </form>
          </div>
          <router-link to="/login" class="auth-link">
            Annuler
          </router-link>
        </div>
      </div>
      <div class="hero-body" v-if="success">
        <div class="auth-block">
          <p>Votre mot de passe a été modifié avec succès,</p>
          <p>Vous pouvez dès à présent vous reconnecter sur l'application.</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { http } from '../../services/api.service'
import { ToastProgrammatic as Toast } from 'buefy'

export default {
  name: 'CreatePassword',
  data () {
    return {
      password: '',
      passwordConfirmation: '',
      success: false
    }
  },
  methods: {
    create () {
      const { password, passwordConfirmation } = this
      http.post(
        '/auth/reset-password', {
          token: this.$route.params.token,
          password,
          password_confirmation: passwordConfirmation
        })
        .then(res => {
          this.success = true
          // this.$router.push('/login')
        })
        .catch(function (err) {
          Toast.open({
            duration: 10000,
            message: err.response.data,
            position: 'is-top',
            type: 'is-danger',
            hasIcon: true
          })
        })
    }
  }
}
</script>

<style lang="scss">
body {
  background: whitesmoke;
}
.hero-body {
  justify-content: center;
  flex-direction: column;
}
.login {
  background: white;
  padding: 25px;
  box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.02);
}
.login-hr {
  border-bottom: 1px solid black;
}

#reset-pwd {
  .hero-body {
    background-color: rgb(248, 247, 247);
    color: #000;
    padding: 1.5rem
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    button {
      border-radius: 1rem;
      background-color: rgb(45,93,251);
    }
  }

  input {
    border-radius: 1rem;
  }

  .auth-form {
    color: #000;
    box-shadow: none;
    padding: 0;
  }

  .header {
    background: #2F4AF4;
    color: #fff;
    height: 9rem;
    padding: 1.5rem;
    display: flex;
    align-items: flex-end;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    letter-spacing: 0.025em;
  }
}

</style>
